.toaster {
  position: fixed;
  right: 10px;
  top: 62px;
  zindex: 99999;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -webkit-transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.alert {
  background-color: #fff;
  border: 2px solid transparent;
}

.alert-info {
  color: #31708f;
  border-color: #31708f;
}

.alert-success {
  color: #4caf50;
  border-color: #4caf50;
}

.alert-warning {
  color: #eeab40;
  border-color: #eeab40;
}

.alert-danger {
  color: #a94442;
  border-color: #a94442;
}

.alert-info:hover {
  color: #31708f;
  border-color: #31708f;
}

.alert-success:hover {
  color: #4caf50;
  border-color: #4caf50;
}

.alert-warning:hover {
  color: #f1b048;
  border-color: #f1b048;
}

.alert-danger:hover {
  color: #a94442;
  border-color: #a94442;
}

.toaster-close {
  position: absolute;
  top: -11px;
  left: -12px;
  padding-left: 5px;
  padding-right: 5px;
  border: 2px solid;
  background: #fff;
  border-radius: 90px;
}
