.direct-chat-messages::-webkit-scrollbar {
    width: 8px;
}

.direct-chat-messages::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 20px #f3f3f3;
    border-radius: 5px;
}

.direct-chat-messages::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 20px #d9d9de;
}
