.bg-custom {
  /* background: linear-gradient(0deg, rgb(43, 144, 217), rgb(84, 178, 247)); */
  background: #374b73;
}

.home-wrapper-alt {
  display: table-cell;
  vertical-align: middle;
}

.home-wrapper {
  padding: 200px 0px 150px 0px;
}

.p-0 {
  padding: 0px !important;
}

.home-sm {
  padding: 55px 0px 60px 0px;
}

.home-sm .h1 {
  margin-top: 30px !important;
}

@media (max-width: 991px) {
  .home-sm .h1 {
    margin-top: 5px !important;
  }
  .home-sm {
    padding: 30px 0px 60px 0px;
  }
}

.home-wrapper h1 {
  line-height: 46px;
  width: 70%;
  margin: 0 auto;
}

.w-full {
  width: 100% !important;
}

.font-light {
  font-weight: 300;
}

.text-white {
  color: #ffffff !important;
}

.home-wrapper h4 {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  width: 80%;
  margin: 30px auto 50px auto;
}

.w-full {
  width: 100% !important;
}

.text-light {
  color: rgba(255, 255, 255, 0.7);
}

.or-space {
  margin: 0 20px;
}

.text-light {
  color: rgba(255, 255, 255, 0.7);
}

.video-btn {
  color: #ffffff !important;
  letter-spacing: 1px;
  outline: none !important;
}

.btn-white-bordered {
  padding: 8px 24px !important;
  background-color: transparent !important;
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  color: #ffffff;
  border-radius: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.intro-form {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 22px 45px -15px rgba(0, 0, 0, 0.5) !important;
  -moz-box-shadow: 0px 22px 45px -15px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0px 22px 45px -15px rgba(0, 0, 0, 0.5) !important;
}

.intro-form h3 {
  color: #949799;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 0px;
}

.intro-form input {
  border: 1px solid #eee;
  height: 38px;
  box-shadow: none !important;
}

.intro-form .form-group:last-of-type {
  margin-bottom: 0;
}

.btn-custom {
  /*border: 2px solid #00b19d !important;*/
  border: 2px solid #26b15a !important;
}

.back-to-top:hover,
.bg-custom,
.btn-custom,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.navbar-custom {
  /* background-color: #26b15a !important; */
  background-color: #374b73 !important;
}

.btn-custom {
  /*padding: 8px 24px !important;*/
  padding: 1px 5px !important;
  color: #ffffff !important;
  border-radius: 50px;
  font-weight: 500;
  /*text-transform: uppercase;*/
  letter-spacing: 0.04em;
}

.btn-hb {
  padding: 8px 24px !important;
  color: #ffffff !important;
  border-radius: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-t-20 {
  margin-top: 20px;
}

.text-muted {
  color: #95a8b7;
}

.step {
  line-height: 50px;
  margin-left: 10px !important;
  font-size: 16px;
  color: #fff;
}
