.nav > li > a:hover,
.nav > li > a:focus {
  background-color: #eee;
}

.dashboard-title {
  margin: 0 20px 20px 20px;
  font-weight: 400;
  text-align: center;
}

.left-navbar {
  padding-left: 0px;
}

.left-navbar-toggle {
  border-color: #777;
  margin-top: 0px;
  color: #777;
  padding: 10px;
  float: left;
}

.user-name {
  font-size: 14px;
  text-align: left;
  padding: 0 10px 10px 25px;
  /*color: #EA785D;*/
  /*color: #009688;*/
  color: #333;
  margin-top: 20px;
  /*font-weight: bold;*/
  font-weight: 500;
}

@media (max-width: 991px) {
  .user-name {
    text-align: left !important;
    /*margin: 0px 0 0 10px;*/
    padding: 0 10px 10px 0px;
  }

  .navbar-collapse {
    border-top: 0px;
  }

  .navbar-collapse.collapse.in {
    overflow-y: auto !important;
  }

  .nav > li > a {
    padding-left: 0px;
  }
}

.user-menu > li > a {
  font-size: 13px;
}

.panel-default > .panel-heading {
  border-color: #ddd;
}

.panel-default {
  border-color: #ddd;
}

.main-content {
  background: #fff;
  /*padding-top: 20px;*/
}

.mc::-webkit-scrollbar {
  width: 8px;
}

.mc::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 20px #f3f3f3;
  border-radius: 5px;
}

.mc::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 20px #d9d9de;
}

.chat-content {
  background: #fff;
  margin: 20px 0 0 20px;
  padding: 10px;
}
